import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "D:/work/BC_Supermarket/bc-supermarket-docs-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "get-started"
    }}>{`Get Started`}</h1>
    <p>{`Theme is customizable and editable via:`}</p>
    <ol>
      <li parentName="ol">{`Theme Editor`}</li>
      <li parentName="ol">{`Template files`}</li>
      <li parentName="ol">{`Language files`}</li>
    </ol>
    <h2 {...{
      "id": "theme-editor"
    }}>{`Theme Editor`}</h2>
    <p>{`This theme provides a powerful Theme Editor with many options let you change styles, colors, fonts and more.`}</p>
    <p>{`To access the Theme Editor, go to your admin page > `}<strong parentName="p">{`Storefront Design`}</strong>{` > `}<strong parentName="p">{`My Themes`}</strong>{`, on the Current Theme section, click `}<strong parentName="p">{`Customize`}</strong>{` button:`}</p>
    <p><img alt="Click customize theme" src={require("./img/click-customize-theme.png")} /></p>
    <h2 {...{
      "id": "edit-language-file"
    }}>{`Edit language file`}</h2>
    <p>{`Language files contains hard-coded static text, images and other links, which can be edited and translated to any languages.`}</p>
    <p>{`To edit the language file, go to admin page > `}<strong parentName="p">{`Storefront Design`}</strong>{` > `}<strong parentName="p">{`My Themes`}</strong>{`, click button `}<strong parentName="p">{`Advanced`}</strong>{` > `}<strong parentName="p">{`Edit Theme Files`}</strong>{` of the current theme:`}</p>
    <p><img alt="Edit theme files" src={require("./img/edit-theme-files.png")} /></p>
    <p>{`From the left navigation, click to edit the language file (e.i `}<inlineCode parentName="p">{`en.json`}</inlineCode>{`):`}</p>
    <p><img alt="Edit language file" src={require("./img/edit-language-file.png")} /></p>
    <h2 {...{
      "id": "edit-template-files"
    }}>{`Edit template files`}</h2>
    <p>{`Edit template files is `}<strong parentName="p">{`not recommended`}</strong>{`, unless you have HTML, CSS and Sass programming skills.`}</p>
    <p>{`You can edit the HTML files, SCSS files, images. However remember to `}<strong parentName="p">{`backup your changed files`}</strong>{` as they are overwritten when you upgrade the theme.`}</p>
    <p>{`To edit the template files, go to admin page > `}<strong parentName="p">{`Storefront Design`}</strong>{` > `}<strong parentName="p">{`My Themes`}</strong>{`, click button `}<strong parentName="p">{`Advanced`}</strong>{` > `}<strong parentName="p">{`Edit Theme Files`}</strong>{` of the current theme:`}</p>
    <p><img alt="Edit theme files" src={require("./img/edit-theme-files.png")} /></p>
    <ul>
      <li parentName="ul">{`All HTML files are in directory `}<inlineCode parentName="li">{`templates`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`All Sass files are in directory `}<inlineCode parentName="li">{`assets / scss`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Images are placed in directory `}<inlineCode parentName="li">{`assets / img`}</inlineCode>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "activate-a-theme-style"
    }}>{`Activate a theme style`}</h2>
    <p>{`To activate a theme style, open Theme Editor, find `}<strong parentName="p">{`Styles`}</strong>{` section, click on the theme variation's thumbnail to activate corresponding style.`}</p>
    <p><img alt="Theme editor style" src={require("./img/theme-editor-style.jpg")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      